/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"


const TestPage2 = () => {
  return (
    <h1>
      Test Page 2
    </h1>
  )
}


export default TestPage2
